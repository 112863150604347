import React, { useEffect, useState } from "react";
import { Grow, Grid } from "@material-ui/core";
import useStyles from "../../styles";
import Posts from "../../components/Posts/Posts";
import Form from "../../components/Form/Form";
import { getPosts, likePost } from "../../actions/posts";
import { useDispatch } from "react-redux";

const Home = () => {
  const [currentId, setCurrentId] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPosts());
  }, [currentId, likePost, dispatch]);

  return (
    <Grow in>
      <Grid
        container
        className={classes.mainContainer}
        spacing={1}
        style={{ margin: 8 }}
      >
        <Grid item xs={12} sm={7}>
          <Posts setCurrentId={setCurrentId} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Form currentId={currentId} setCurrentId={setCurrentId} />
        </Grid>
      </Grid>
    </Grow>
  );
};

export default Home;
